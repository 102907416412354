import React from "react";
import Banner from "../containers/App/Banner";
import HeaderMenuBS from "../containers/App/HeaderMenuBS";
import Footer from "../containers/App/Footer";
import FeatureCards from "../containers/App/FeatureCards";
import AppFeatures from "../containers/App/AppFeatures";
import Pricing from "../containers/App/Pricing";
import Download from '../containers/App/Download';
import About from "../containers/App/About";
import Contact from "../containers/App/Contact";
import Faq from "../containers/App/Faq";
import GlobalStyle from "../containers/App/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/App/Common/fonts"
import BuyNow from "../components/BuyNow";

const App = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       {/* <BuyNow /> */}
       <HeaderMenuBS/>
       <main>
            <Seo 
                title="Museli - Your Practice Companion"
                description="Perfect your practice with online video lessons, dynamic sessions and your personal lesson library"
            />
            <Banner/>
            <AppFeatures/>
            {/* <FeatureCards/>
            <About/> */}
            <Pricing/>
            <Download/>
            {/* <Contact/> */}
            {/* <Faq/> */}
        </main>
       <Footer/>
   </div>

)

export default App;
