import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentLeft,
    BannerContentRight,
    BannerBtn
} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";
import DownloadApp from "../Download/StoreDownload";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`{
  appJson {
    Banner {
      DelayConstant
      BannerBtn
      BannerH3Line1
      BannerH3Line2
      BannerImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      BannerPara
    }
  }
}
`);
    return (
        <BannerSection id="homeContainer">
            <Container>
                    <BannerContents>
                        <BannerContentLeft>
                            <Fade bottom delay={1*JSONData.appJson.Banner.DelayConstant}>
                                <div>
                                    <h1>{JSONData.appJson.Banner.BannerH3Line1}</h1>
                                    <h1>{JSONData.appJson.Banner.BannerH3Line2}</h1>
                                    <p>{JSONData.appJson.Banner.BannerPara}</p>
                                    {/* <BannerBtn href="#">{JSONData.appJson.Banner.BannerBtn}</BannerBtn> */}
                                    <DownloadApp/>
                                </div> 
                            </Fade>
                            </BannerContentLeft>
                            <BannerContentRight>
                              <Fade bottom delay={1*JSONData.appJson.Banner.DelayConstant}>
                                  <GatsbyImage
                                      image={JSONData.appJson.Banner.BannerImage.childImageSharp.gatsbyImageData}
                                      className="BannerImage"
                                      imgStyle={{
                                          marginBottom:"0px",
                                      }}
                                      alt="" />
                              </Fade>
                            </BannerContentRight>
                    </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;
