import styled from 'styled-components';
import {Commonbtn,SectionHeading,SectionSubHeading} from '../Common/common.style';
import {device} from '../Common/device';

export const PricingSection = styled.section`
    padding: 60px 0px;
    background: #f9fafc;
    background-size:100%;
    position:relative;

    @media ${device.tablet} {
        padding: 50px 0px;
    }
`;

export const PricingInner = styled.div`
    content:"";
    background-repeat: no-repeat;
    background-position: -300px;
    background-size: 600px;

    @media ${device.tablet} {
        background-size: 500px;
        background-position: -250px 0px;
        padding-left:10px;
        padding-right:10px;
    }
`;

export const PricingHeadingWrapper = styled.div`
    max-width:600px;
    margin:auto;
    text-align:center;
    margin-bottom:40px;

    @media ${device.tablet} {
        margin-bottom:40px;
    }
`;

export const PricingSubHeading = styled(SectionSubHeading)`
    margin-bottom:0px;
    text-align:center;
`;

export const PricingHeading = styled(SectionHeading)`
    text-align:center;
`;

export const PricingCustomRow = styled.div`
    display:flex;
    justify-content: center;
    @media ${device.tablet} {
        display:block;
    }
`;

export const PricingCol = styled.div`
    width:33.33%;
    margin: 15px 0;

    @media ${device.tablet} {
        width:100%;
    }
`;

export const PricingPlanCol = styled.div`
    background:#fff;
    border-radius: 15px;
    text-align:center;
    padding: 60px 25px;
    box-shadow: 0px 0px 30px 0px #d8d8d8;
    margin:20px 10px;

    border-top-left-radius: ${props => props.index === 2 ? "0px ":"15px"};
    border-bottom-left-radius: ${props => props.index === 2 ? "0px ":"15px"};

    border-top-right-radius: ${props => props.index === 0 ? "0px ":"15px"};
    border-bottom-right-radius: ${props => props.index === 0 ? "0px ":"15px"};

    @media ${device.tablet} {
        border-radius:15px;
        margin:0px 0px;
    }

    img{
        width:80px;
        margin-bottom:25px;
    }
    h6{
        color:#5ce59b;
        margin-bottom:30px;
        font-size: 18px;
        font-weight:bold;
        text-transform:uppercase;
    }
    h1{
        font-size: 43px;
        line-height: 1;

        span
        {
            font-size: 18px;
            text-transform: uppercase;
            margin-top: 15px;
            display: block;
            color: #6c6c6c;
            text-align: center;
        }

        @media ${device.laptop} {
            font-size: 32px;
            span
            {
                font-size: 16px;
            }
        }

        @media ${device.tablet} {
            font-size: 43px;
            span
            {
                font-size: 18px;
            }
        }
    }

`;

export const PricingPlanColCenter = styled.div`
    border-radius:15px;
    text-align: center;
    padding: 80px 25px;
    box-shadow: 0px 0px 30px 0px #d8d8d8;
    background: #fff;
    position: relative;
    z-index: 9;

    @media ${device.laptop} {
        padding-top: 40px 15px 40px 15px;
    }

    @media ${device.tablet} {
        transform: none;
        padding-top: 40px 25px;
        margin:20px 0px;
    }

    img{
        width:80px;
        margin-bottom:25px;
    }
    h6{
        color:#5ce59b;
        margin-bottom:30px;
        font-size: 18px;
        font-weight:bold;
        text-transform:uppercase;
    }
    h1{
        font-size: 43px;
        line-height: 1;

        span
        {
            font-size: 18px;
            text-transform: uppercase;
            margin-top: 15px;
            display: block;
            color: #6c6c6c;
            text-align: center;
        }

        @media ${device.laptop} {
            font-size: 32px;
            span
            {
                font-size: 16px;
            }
        }

        @media ${device.tablet} {
            font-size: 43px;
            span
            {
                font-size: 18px;
            }
        }
    }
`;

export const PricingPlanLine = styled.hr`
    background: #5ce59b;
    max-width: 50%;
    margin: 30px auto;
`;

export const PricingPlanDarkText = styled.p`
    color:#595959;
    font-weight:500;
    margin-bottom:5px;
    font-size:16px;

    @media ${device.laptop} {
        font-size:14px;
    }
    @media ${device.tablet} {
        font-size:16px;
    }
`;

export const PricingPlanLightText = styled.p`
    color:#b2b2b2;
    margin-bottom:5px;
    font-weight:500;
    font-size:16px;

    @media ${device.laptop} {
        font-size:14px;
    }

    @media ${device.tablet} {
        font-size:16px;
    }
`;

export const PricingPlanBtn = styled(Commonbtn)`
    margin-top: 30px;
`;

export const PricingPlanBtnCenter = styled(Commonbtn)`
    margin-top: 30px;
`;
