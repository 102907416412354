import React from 'react';
import {AppFeaturesSection,AppFeaturesHeadingWrapper,
    AppFeaturesHeading,AppFeaturesSubHeading,AppFeaturesContent,AppFeaturesWrapper,
    AppFeaturesText,AppFeaturesImgHolder,AppFeaturesLeftIcon,
    AppFeaturesCenter,CenterCol, AppFeaturesScreenshot
} from './appfeatures.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";

const AppFeatures = () => {
    const JSONData  = useStaticQuery(graphql`{
  appJson {
    AppFeatures {
      AppFeaturesHeading
      AppFeaturesSubHeading
      AppFeaturesContent1 {
        AppFeaturesLeftBold
        text
        AppFeaturesImg
      }
      AppFeaturesContent2 {
        AppFeaturesMobile {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        AppFeaturesMobile2 {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
      }
      AppFeaturesContent3 {
        AppFeaturesLeftBold
        text
        AppFeaturesImg
      }
    }
  }
}
`);
    return (
        <AppFeaturesSection id="featuresContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.appJson.AppFeatures.DelayConstant}>
                            <AppFeaturesHeadingWrapper>
                                <AppFeaturesHeading>{JSONData.appJson.AppFeatures.AppFeaturesHeading}</AppFeaturesHeading>
                                <AppFeaturesSubHeading>{JSONData.appJson.AppFeatures.AppFeaturesSubHeading}</AppFeaturesSubHeading>
                            </AppFeaturesHeadingWrapper>
                        </Fade>
                    </Col>
                </Row>

                <AppFeaturesContent>
                    <Col md="6" lg="4">
                    <Fade left delay={(2)*JSONData.appJson.AppFeatures.DelayConstant}>
                    {
                        JSONData.appJson.AppFeatures.AppFeaturesContent1.map((item,idx) => {
                        return <AppFeaturesWrapper>
                                <AppFeaturesLeftIcon>
                                    <AppFeaturesImgHolder>
                                        <img src={item.AppFeaturesImg} alt=""/>
                                    </AppFeaturesImgHolder>
                                </AppFeaturesLeftIcon>
                                <AppFeaturesText>
                                    <h6>{item.AppFeaturesLeftBold}</h6>
                                    <p>{item.text}</p>
                                </AppFeaturesText>

                            </AppFeaturesWrapper>
                        })
                    }
                    </Fade>
                    </Col>

                    <CenterCol md="0" lg="4">
                        <AppFeaturesCenter>
                            <GatsbyImage
                                image={JSONData.appJson.AppFeatures.AppFeaturesContent2.AppFeaturesMobile.childImageSharp.gatsbyImageData}
                                className="AppFeaturesMobile"
                                imgStyle={{
                                    marginBottom:"0px",
                                    width:"100%"
                                }}
                                alt="" />
                        </AppFeaturesCenter>
                    </CenterCol>

                    <Col md="6" lg="4">
                    <Fade right delay={(2)*JSONData.appJson.AppFeatures.DelayConstant}>
                    {
                        JSONData.appJson.AppFeatures.AppFeaturesContent3.map((item,idx) => {
                            return <AppFeaturesWrapper>
                                <AppFeaturesLeftIcon>
                                    <AppFeaturesImgHolder>
                                        <img src={item.AppFeaturesImg} alt=""/>
                                    </AppFeaturesImgHolder>
                                </AppFeaturesLeftIcon>
                                <AppFeaturesText>
                                    <h6>{item.AppFeaturesLeftBold}</h6>
                                    <p>{item.text}</p>
                                </AppFeaturesText>
                            </AppFeaturesWrapper>
                        })
                    }
                    </Fade>
                    </Col>
                </AppFeaturesContent>
                <AppFeaturesScreenshot>
                    <Fade left delay={50}>
                        <GatsbyImage
                            image={JSONData.appJson.AppFeatures.AppFeaturesContent2.AppFeaturesMobile2.childImageSharp.gatsbyImageData}
                            className="AppFeaturesScreenshot"
                            imgStyle={{
                                marginBottom:"0px",
                                width:"100%",
                                objectFit:"contain",
                                maxHeight: "560px"
                            }}
                            alt="" />
                    </Fade>
                </AppFeaturesScreenshot>
            </Container>
        </AppFeaturesSection>
    );
}

export default AppFeatures;
