import React from 'react';

import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import {DownloadSection,DownloadInner,DownloadCol1,DownloadContentBg,
    DownloadLinkWrapper,DownloadLeftCol,DownloadCol2,SubHeading,Heading
} from './download.style';
import Fade from "react-reveal/Fade";

const DownloadApp = () => {
    const JSONData  = useStaticQuery(graphql`{
        appJson {
          Download {
            DelayConstant
            DownloadTitle
            DownloadSubTitle
            DownloadPara
            DownloadLinkImgApple {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            DownloadLinkImgGoogle {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            DownloadDemo {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      `);
    
    return(

        // <div className={styles.StoreIconContainer}>
        //     <button className={styles.StoreIcon}><img src={GetOnIos} width="200"  onClick={() => window.open('https://apps.apple.com/us/app/id1611874911')}/></button>
        //     <button className={styles.StoreIcon}><img src={GetOnAndroid} width="200" onClick={() => window.open('https://play.google.com/store/apps/details?id=app.museli.play')}/></button>
        // </div>

        <div>
            <Fade left delay={1*JSONData.appJson.Download.DelayConstant}>
                <DownloadLinkWrapper>
                    <DownloadLeftCol
                      onClick={() => window.open('https://apps.apple.com/us/app/id1611874911')}>
                        <GatsbyImage
                          image={JSONData.appJson.Download.DownloadLinkImgApple.childImageSharp.gatsbyImageData}
                          imgStyle={{
                              marginBottom:"0px",
                          }}
                          className="DownloadImage"
                          alt="" />
                    </DownloadLeftCol>
                    <DownloadLeftCol
                      onClick={() => window.open('https://play.google.com/store/apps/details?id=app.museli.play')}>
                        <GatsbyImage
                          image={JSONData.appJson.Download.DownloadLinkImgGoogle.childImageSharp.gatsbyImageData}
                          className="DownloadImage"
                          imgStyle={{
                              marginBottom:"0px",
                          }}
                          alt="" />
                    </DownloadLeftCol>
                </DownloadLinkWrapper>
            </Fade>
        </div>
    );
}

export default DownloadApp;