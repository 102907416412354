import styled from 'styled-components';
import {Commonbtn} from '../Common/common.style';
import {device} from '../Common/device';

export const BannerSection = styled.section`
    background-color: white;
    // background-image: radial-gradient(circle, #14574Ef8, #14574Ef);
    background-image: radial-gradient(circle, #0b1d27f8, #0b1d27);
    background-repeat:no-repeat;
    background-size:cover;
    min-height: 100vh;
    display:flex;
    align-items:center;
    // padding:100px 0px;

    // @media ${device.tablet} {
        // padding:50px 10px;
    // }

`;

export const BannerContents = styled.div`
    // display:flex;
    align-items: center;

    // @media ${device.tablet} {
        display:block;
        padding: 120px 0 80px 0;
    // }
`;

export const BannerContentLeft = styled.div`
    // width:50%;

    // @media ${device.tablet} {
        width:100%;
        text-align:center;
        padding-right:0px;
    // }

    h1{
        color:#fff;
        margin-bottom:0px;
        font-weight:400;
    }

    p{
        color:#fff;
        font-size:16px;
        margin-bottom:30px;
        margin-top:25px;
    }
`;


export const BannerBtn = styled(Commonbtn)`
    text-decoration:none;
`;

export const BannerContentRight = styled.div`
    // width:50%;
    // text-align:right;
    text-align:center;
    padding-top:45px;
    width: 630px;
    margin: auto;

    @media ${device.tablet} {
        width:100%;
    }

    .BannerImage{
        position: relative;
        z-index: 999;
        max-width: 85vw;
        margin: 0px auto;
    }
`;
